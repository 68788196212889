.bringo-select-payment,
.bringo-complete-summary {
  .invisible {
    opacity: 0;
    pointer-events: none;
  }
}

.bringo-select-payment {
  .sgr-pay {
    align-items: center;

    .content-wrapper {
      display: flex;
      flex-grow: 1;
      align-items: center;
    }

    &.loading {
      pointer-events: none;

      .content-wrapper {
        opacity: .2;
      }
    }

    .spinner-loader {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    .content {
      display: flex;
      align-items: center;

      .details {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      img {
        max-width: 40px;
        margin-right: 0.75rem;
      }
    }

    .description {
      .voucher-value {
        strong {
          color: map-get($colors, 'color_3');
        }
      }
    }
  }
}

.bringo-complete-summary {
  .sgr-pay {
    img {
      width: 45px;
    }
  }

  .sgr-pay-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;

    label.title-input {
      margin-bottom: 4px;
      font-weight: 700;
      color: map-get($colors, 'color_3');
    }
  }

  .sgr-input-container {
    display: flex;
    flex-direction: column;

    .input-group {
      justify-content: center;
    }

    button,
    input {
      border-radius: 100%;
    }

    button {
      min-height: 2.5rem;
      background: map-get($colors, 'color_3');
      color: white;
      border: 0;
    }

    input {
      text-align: center;
      max-width: 45px;
      border: 0;
      background: transparent;
      margin: 0;
      padding: 0;
      font-size: 1.25rem;
      color: map-get($colors, 'color_3');
      font-weight: 700;
    }
  }

  .box-body {
    text-align: justify;

    span.text {
      border-bottom: 1px solid map-get($colors, 'color_8');;
      padding-bottom: 10px;
    }
  }
}

.sgr-info-button {
  display: flex;
  align-items: center;
  border-radius: 1rem !important;

  img {
    width: 22px !important;
    margin-right: .25em;
  }
}

#sgrInformationModal {
  iframe {
    width: 100%;
    max-height: 480px;
    height: calc(100vh - 140px);
    box-shadow: $box-shadow;
    border-radius: $border-radius-value;
    background: linear-gradient(-45deg, #DBDBDB, white);
  }
}
