$border-radius-value: 1em;
$greyWhite: #F3F3F3;
$box-shadow: 4px 4px 8px -4px #00000030;
$color-primary-dark: #362768;

.store-schedule-button {
  border-radius: 2rem!important;
  margin-left: .25rem;
}

.store-directions-button {
  border-radius: 1rem!important;
  margin-right: .25rem;

  img {
    margin-right: .5em;
  }
}

.store-schedule-button,
.store-directions-button {
  &:hover {
    background-color: $color-primary-dark!important;
    border-color: $color-primary-dark!important;
  }

  img {
    width: 1.5em;
  }
}

.store-schedule-modal {
  .info-box {
    border-radius: $border-radius-value;
    background-color: $greyWhite;
    box-shadow: $box-shadow;
    padding: .5rem .75rem;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    h2 {
      font-size: 1.25rem;
      font-weight: 700;
    }

    p {
      margin-bottom: 0;
    }

    table {
      width: 100%;

      tr {
        td {
          &:last-child {
            text-align: right;
            font-weight: bold;
          }
        }
      }
    }

    .certificates-of-operation {
      img {
        width: 100%;
        max-width: 150px;
      }

      div[class*="col-"] {
        width: 100%;
        max-width: 150px;
      }
    }

    .address-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
