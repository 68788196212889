@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.company-invoice {
  &.full-width-cards {
    .company-cards {
      .card-wrapper {
        width: 100%;
      }
    }
  }

  .company-cards {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;

    .card-wrapper {
      width: 33.33%;
      padding: 1rem;

      @media (max-width: 1259px) {
        width: 50%;
      }

      @media (max-width: 767.98px) {
        width: 100%;
      }

      .card {
        height: 100%;
        box-shadow: 1px 1px 10px 0 #0000001c;
        border: 1px solid white;

        &.with-card-selection {
          cursor: pointer;

          &:hover {
            box-shadow: 1px 1px 10px 0 #00000040;
          }

          &, &:hover {
            transition: box-shadow 0.25s, background-color .25s;
          }

          &:hover, &.selected {
            background-color: #f4f4f4;
          }

          &.selected {
            border: 1px solid map-get($colors, 'color_9');
          }
        }

        &.loading {
          min-height: 200px;
          background: linear-gradient(100deg, #ffffff 8%, #e8e8e8 18%, #ffffff 33%);
          border-radius: 5px;
          background-size: 200% 100%;
          animation: 2.5s shine linear infinite;
        }

        &.add {
          align-items: center;
          justify-content: center;
          border: 0;
          box-shadow: none;
        }

        &:not(.add) {
          .card-body {
            padding: 2rem 1rem 1rem;
          }
        }

        .card-buttons {
          position: absolute;
          right: 0.5rem;
          top: -1.375rem;

          .btn {
            margin: 0px 2px;
            box-shadow: 1px 1px 10px 0 #00000033;
          }
        }
      }
    }
  }

  .company-invoice-paginator-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .company-invoice-paginator {
    margin: 1rem auto .5rem;

    .pagination {
      margin: 0;
      box-shadow: 1px 1px 10px 0 #0000001c;
    }

    .page-item {
      &.disabled {
        pointer-events: none;
        opacity: .25;
      }

      &.active {
        .page-link {
          background-color: map-get($colors, 'color_1');
          border-color: map-get($colors, 'color_8');
          color: white;

          &:hover {
            background-color: map-get($colors, 'color_1');
          }
        }
      }

      .page-link {
        &:hover {
          background-color: map-get($colors, 'color_8');
        }
      }
    }
  }
}
