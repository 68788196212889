$product-icon-image-size: 34px;
$product-icon-image-size-big-ratio: 1.25;

.bringo-product-listing {
  .product-flags {
    justify-content: center;
  }
}

.product-flags {
  display: flex;
  align-items: center;

  &.large-icons {
    img {
      width: 45px;
    }
  }

  img {
    width: 30px;
  }
}

.product-icon-image {
  box-shadow: 2px 2px 6px 0 #0000005c;
  outline: 2px solid white;
  border-radius: 100%;
  width: $product-icon-image-size;
  height: $product-icon-image-size;
  margin: 0 5px;
  overflow: hidden;
  background-position: center;
  background-size: $product-icon-image-size;
  flex-shrink: 0;

  &.icon-big {
    width: $product-icon-image-size * $product-icon-image-size-big-ratio;
    height: $product-icon-image-size * $product-icon-image-size-big-ratio;
    background-size: $product-icon-image-size * $product-icon-image-size-big-ratio;
  }

  &.sgr {
    background-repeat: no-repeat;
    box-shadow: none;
  }
}
