$afg-price-color: map-get($colors, 'color_14');
$afg-price-font-size: 19px;

.afg-price {
  position: relative;

  .afg-price-row {
    img {
      max-width: 30px;
      margin-right: 5px;
    }

    .product-price {
      display: flex;
    }
  }
}

.bringo-product-listing {
  .afg-price {
    clear: both;

    .afg-price-row {
      .product-price {
        color: #1c262f;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}

.bringo-product-details {
  .afg-price {
    margin: 10px 0;

    .afg-price-row {
      display: flex;

      .product-price {

        color: $afg-price-color;
        font-size: $afg-price-font-size;

        span {
          margin: 0 5px;
          font-weight: 300;
          color: grey;
          font-size: $afg-price-font-size - 2;
        }
      }

      .discount-procentage {
        position: static;
        width: 43px;
        height: 20px;
        font-size: 13px;
        background-color: #da3832;
      }
    }
  }
}

.bringo-product-listing ,
.bringo-product-details {
  .afg-price {
    .product-price {
      align-items: center;
    }
  }
}

