$color-primary: map-get($colors, 'color_1');

.invite-referral-modal {
  .modal-dialog {
    max-width: 800px;
  }

  .modal-content {
    text-align: center;

    .referral-description {
      font-weight: 400;
      font-size: 16px
    }

    .referral-link {
      color: $color-primary;
      font-weight: 700;
      text-decoration: underline;
    }

    .clipboard-copy {
      width: 100%;
    }

    iframe {
      width: 100%;
      border-radius: 5px;
      border: 0;
      box-shadow: 0 1px 4px rgba(0, 0, 0, .25);
      height: calc(100vh - 290px);
    }
  }
}
