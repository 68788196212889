$colorA: #23823f;
$colorB: #86bd2b;
$colorC: #facc04;
$colorD: #ef8102;
$colorE: #e63c07;

.nutri-score {
  zoom: .75;
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 220px;
  height: 120px;

  @media (max-width: 992px) {
    zoom: .6;
    margin: -20px auto 35px;
  }

  &.absolute-position {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .title {
    color: #7e7e7e;
    font-size: 20px;
    margin: 10px;
  }

  .scores-container {
    position: relative;
    height: 72px;
    border-radius: 20px;
    margin: 0 5px;

    .scores {
      display: flex;
      align-content: stretch;
      height: 100%;
    }

    span {
      flex-grow: 1;
      text-align: center;
      color: hsla(0, 0%, 100%, .5);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      font-weight: 700;
    }

    .a {
      background-color: $colorA;
      border-radius: 20px 0 0 20px;
    }

    .b {
      background-color: $colorB;
    }

    .c {
      background-color: $colorC;
    }

    .d {
      background-color: $colorD;
    }

    .e {
      background-color: $colorE;
      border-radius: 0 20px 20px 0;
    }
  }

  .score {
    &.a {
      background-color: $colorA;
      left: -5px;
    }

    &.b {
      background-color: $colorB;
      left: 40px;
    }

    &.c {
      background-color: $colorC;
      left: 80px;
    }

    &.d {
      background-color: $colorD;
      left: 122px;
    }

    &.e {
      background-color: $colorE;
      left: 168px;
    }

    color: #fff;
    width: 52px;
    height: 85px;
    border: 3px solid #fff;
    position: absolute;
    top: -7px;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 52px;
    border-radius: 12px;
  }
}

