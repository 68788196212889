$border-radius-size: 15px;
$color-primary: map-get($colors, 'color_1');
$color-secondary: map-get($colors, 'color_2');
$color-secondary-white: #FFF1CF;
$color-grey: #F2F2F2;
$color-grey-2: #E5E5E5;
$color-white-opacity: #FFFFFFDE;
$transition-bg: background-color .25s ease;
$transition-opacity: opacity .25s ease;
$shadow: 2px 2px 6px -2px #00000026;

.global-store-search {
  &.overlay-opened {
    z-index: 9999 !important;
  }

  .disabled {
    pointer-events: none;
  }
}

.global-store-product-search-modal-backdrop {
  opacity: 0 !important;
}

.global-store-product-search-modal {
  overflow: hidden !important;

  .modal-body {
    padding: 5px;
  }

  .modal-dialog {
    margin: 0 auto;
    max-width: 1160px;
    overflow-x: hidden;
    border-radius: $border-radius-size;

    &.narrow {
      max-width: 500px;
      margin: 0 auto;
    }

    @media (min-width: 1459px) {
      max-width: 1340px;
    }

    @media (max-width: 1259px) {
      max-width: 900px;
    }

    @media (max-width: 991px) {
      max-width: 660px;
    }

    @media (max-width: 767px) {
      max-width: 100%;
      margin: 0 15px;
    }
  }

  .modal-content {
    border: 0;
    border-radius: $border-radius-size;
  }

  .modal-container {
    &.loading {
      pointer-events: none;
    }
  }
}

.global-store-products-list {
  margin: 0;

  div[class*="col-"] {
    padding: 5px;
  }

  .bringo-product-listing {
    margin-bottom: 0;

    & > a {
      &, &:hover {
        transition: $transition-opacity;
      }

      &:hover {
        opacity: .7;
        text-decoration: none;
      }
    }

    .box-product {
      margin-bottom: 0;
      padding: 5px;
      box-shadow: none!important;

      .image-product {
        margin: 0 auto;
        width: inherit!important;
        min-height: inherit;
        max-height: inherit;
      }
    }

    .bringo-product-name {
      margin: 0 !important;
    }
  }

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.global-store-search-store-card {
  background-color: $color-grey;

  &:hover {
    background-color: $color-grey-2;
  }

  img {
    width: 90px;
    border-radius: $border-radius-size;
    margin-right: 0.75rem;
  }

  .store-details {
    display: flex;
    flex-direction: column;

    .store-name {
      font-size: 18px;
      font-weight: 700;
    }
  }

  .right-wrapper {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 0.75rem;
    font-weight: 700;
    text-align: right;
  }
}

.global-store-search-keyword-card {
  cursor: pointer;
  background: white;

  &.alternate-color {
    background-color: $color-grey;

    &:hover {
      background-color: $color-grey-2;
    }
  }

  &:hover {
    text-decoration: none;
    background-color: $color-grey;
  }

  i {
    padding: 10px;
  }

  .content {
    &, p {
      margin: 0;
    }

    .keyword {
      font-size: 18px;
      font-weight: 700;
    }

    .details {
      font-size: 14px;

      .where {
        font-weight: 700;
        color: $color-primary;
      }

      .results-count {
        font-weight: 700;
      }
    }
  }
}

.global-store-search-input {
  &.disabled {
    pointer-events: none;
  }

  &.loading {
    padding-right: 105px!important;
  }
}

.spinner-loader-search-input {
  display: flex;
  transform: translateY(-50%);
  top: 50%;
  position: absolute;
  right: 16px;
  pointer-events: none;
  background-color: $color-secondary;

  .spinner-border {
    color: white;
  }
}

.global-store-search-store-card,
.global-store-search-keyword-card {
  border-radius: $border-radius-size;
  box-shadow: $shadow;
  padding: 5px;
  display: flex;
  align-items: center;

  &, &:hover {
    color: black;
    transition: $transition-bg;
  }

  &:hover {
    text-decoration: none;
  }
}

.global-store-card-loading {
  &.animation {
    background: linear-gradient(100deg, #ffffff 8%, #e8e8e8 18%, #ffffff 33%);
    background-size: 200% 100%;
    animation: 1s shine linear infinite;
    height: 45px;
  }

  &.keyword-card {
    height: 55px;
  }

  &.store-card {
    height: 80px;
  }
}

.global-store-no-results {
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  padding: 5px 0;
}

.global-store-card-loading,
.global-store-search-keyword-card,
.global-store-search-store-card {
  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

.stores-switch-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 59px;
  border: 1px solid $color-grey-2;
  border-left: 0;
  border-right: 0;
  background: $color-white-opacity;
  height: 50px;
  padding: 0 10px 0 26px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
}

.stores-switch {
  &:checked {
    background-color: #4a397e;
    border-color: #4a397e;
  }
}

.store-search {
  .search-btn {
    &.disabled {
      cursor: wait;
    }

    &.not-available {
      cursor: not-allowed;
    }
  }
}
