$font-size: 16px;
$border-radius-value: 1em;
$color: #2DA771;
$color-darker: darken($color, 10%);

.one-cart-click-button {
    font-size: $font-size;

    &.btn {
        border-radius: $border-radius-value;
        background-color: $color!important;
        border-color: darken($color, 1%)!important;

        &[class*="btn-icon"] {
            display: flex;
            align-items: center;
        }

        &:hover {
            background-color: $color-darker!important;
            border-color: darken($color-darker, 1%)!important;
        }

        img {
            width: 1.5em;
            margin-right: .5em;
        }
    }
}

.one-cart-click-modal {
    font-size: $font-size;

    h1 {
        font-size: 1.5em;
        color: white;
        font-weight: 300;
        margin-top: .75em;
    }

    h2 {
        font-size: 1em;
        margin-bottom: 0.75em;
        font-weight: 700;
    }

    .modal-dialog {
        max-width: 800px;
    }

    .one-cart-click-modal-details {
        padding: 1.5em 0;
        text-align: center;
        background-color: $color;
    }

    .modal-body {
        font-size: 1em;
        div[class*="col-"] {
            display: flex;
            flex-direction: column;
            justify-content: center;

            &:last-child {
                border-left: 1px solid #eaecef;
            }
        }
    }

    .modal-footer {
        .btn {
            width: 100%;
            padding: .75em 0;
            font-size: 1em;
            font-weight: bold;
        }
    }

    ul {
        list-style-image: url('/bundles/_themes/ascend/all-in-one-theme/syliusshop/img/OneCartClickListIcon.svg');

        li:not(:last-child) {
            margin-bottom: 1em;
        }
    }

    button {
        &.disabled {
            pointer-events: none;
            cursor: not-allowed;
        }
    }
}
