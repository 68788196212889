.footer-disclaimer {
  display: flex;
  text-align: center;
  background: white;
  justify-content: center;
  margin: 0 2rem;
  @include box-with-shadow;

  &__content {
    @media (min-width: 768px) {
      display: flex;
    }

    padding: .5rem 0;
  }

  .image {
    @media (max-width: 767px) {
      margin-bottom: 1rem;
    }

    display: flex;
    margin-right: 2rem;
    align-items: center;
    justify-content: center;
  }
}
