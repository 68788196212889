$greyWhite: #EDEDED;
$greyWhite2: #F3F3F3;
$grey: #FFEAD2;
$grey2: #DBDBDB;
$box-shadow: 4px 4px 8px -4px #00000030;

$border-radius-value: 20px;

.ticket-card-info {
  display: flex;
  align-items: center;
}

.ticket-card-info,
.ticket-card-details {
  border-radius: $border-radius-value;
  border-color: $greyWhite;
}

.ticket-card-details.with-gradient,
.styled-control.with-gradient {
  background: linear-gradient(90deg, $greyWhite, $greyWhite2 50%);
}

.ticket-card-details {
  margin-bottom: 8px;

  .card-body {
    &:not(:first-child) {
      border-top: 1px solid $grey2;
    }
  }

  &.is-selected {
    background-color: $grey;
  }

  &.with-action {
    cursor: pointer;

    &:hover {
      border-color: $grey2;
      box-shadow: $box-shadow;
    }
  }

  &, &:hover {
    transition: border-color .25s, box-shadow .25s;
  }
}

.ticket-card-details .ticket-card-details-block span.material-icons,
.ticket-card-details:hover .ticket-card-details-block span.material-icons {
  transition: color .25s;
}

.ticket-card-details {
  .card-body {
    display: flex;
    justify-content: space-between;
  }

  .provider-logo {
    width: 90px;
    height: 60px;
    background-color: white;
    padding: 4px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: $box-shadow;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .provider {
    font-weight: 700;
    font-size: 18px;
    margin: 0;
  }

  .balance {
    margin: 0;
  }
}

.ticket-card-details-balance {
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 100%;

  .linear-progress {
    margin-top: 5px;
    height: 16px;
    border-radius: $border-radius-value;
    max-width: 160px;
  }
}

.ticket-card-details-block {
  display: flex;
  align-items: center;
}

#ticketCardPaymentModal {
  .styled-control {
    justify-content: space-between;
  }

  .money-value {
    width: 80px;
    border-radius: $border-radius-value;
    text-align: center;
    border: 1px solid $greyWhite;
    box-shadow: 3px 3px 3px -1px $grey2;

    &.error {
      border-color: #f7993d;
    }
  }

  .money-value-wrapper {
    display: flex;
    align-items: center;
  }

  .confirm-btn {
    width: 100%;
    font-size: 18px;
    border-radius: $border-radius-value;
    padding: 1rem;
    background: #ff981f!important;
    border: 0;
  }

  .payment-method {
    p {
      margin: 0;
    }
  }
}

#ticketCardExternalPayModal {
  iframe {
    width: 100%;
    max-height: 480px;
    height: calc(100vh - 140px);
    box-shadow: $box-shadow;
    border-radius: $border-radius-value;
    background: linear-gradient(-45deg, #DBDBDB, white);
  }
}

#ticketCardModal,
#ticketCardPaymentModal {
  .modal-header {
    background: linear-gradient(360deg, white, #DBDBDB);
  }
  .modal-body {
    .alert {
      border-radius: $border-radius-value;
    }
  }
  .modal-footer {
    background: linear-gradient(white, #DBDBDB);
  }
}

.payment-details {
  padding: 0!important;

  .ticket-card-details-container,
  .ticket-card-details {
    height: 100%;
  }

  .payment-details-split-bill {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;

    hr {
      border-color: $grey2;
      width: 100%;
      margin: 25px 0;
      height: 1px;
    }

    p {
      margin-bottom: 6px;
    }

    .amount-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: white;
      border-radius: $border-radius-value;
      padding: 8px 16px;
      box-shadow: 3px 3px 6px -3px #00000008;

      p {
        display: flex;
        align-items: center;
        margin-bottom: 0;
      }

      strong {
        font-weight: bold;
      }
    }
  }
}
