.product-price-default-uom {
  color: map-get($colors, 'color_1');
}

.product-price-calculator {
  &--product-page {
    @include box-with-shadow;
    margin: 0.5rem 0;
  }

  color: map-get($colors, 'color_1');

  p {
    margin: .25rem;;

    &.small {
      font-size: .75rem;
    }
  }
}
