@mixin shadow-grey {
  box-shadow: 0 1px 4px rgba(0, 0, 0, .25);
}

@mixin box-with-shadow {
  @include shadow-grey;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 0.5rem 1rem;
}
