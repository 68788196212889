$border-radius-size: 10px;
$spacing-margin: 0.75rem;
$color-primary: map-get($colors, 'color_1');
$color-secondary: map-get($colors, 'color_2');
$gradient-primary: linear-gradient(-45deg, $color-primary, map-get($colors, 'color_10'));
$color-grey-1: #F2F2F2;
$shadow: 2px 2px 6px -2px #00000026;
$transition-bg: background-color .5s ease;
$transition-color: color .5s ease;
$box-shadow-inset-size: 13px;

#cart-big {
  display: flex;
  flex-direction: column;
  background: white;

  .top-cart-section {
    background: white;
  }

  .multi-vendor-cart-title {
    display: flex;
    align-items: center;
    color: $color-primary;
    cursor: pointer;

    i {
      margin-left: auto;
      font-size: 1.5rem;
      padding: $spacing-margin;

      &:hover {
        color: $color-secondary;
      }

      &, &:hover {
        transition: $transition-color;
      }
    }

    .my-cart-title span {
      font-size: 2rem;
      font-weight: 500;
      bottom: 0;
    }
  }
}

.multi-vendor-total-products {
  text-transform: capitalize;
  font-size: 1.5rem;

  span {
    font-weight: 300;
    margin-left: .25rem;
  }
}

.multi-vendor-store-card {
  display: flex;
  align-items: center;
  color: $color-primary;

  a {
    display: flex;
    width: 100%;
    align-items: center;
    font-weight: 500;
    padding: 5px;
    background: $color-grey-1;
    border-radius: $border-radius-size;
    box-shadow: $shadow;
    color: map-get($colors, 'color_1');

    &:hover {
      text-decoration: none;
      color: white;
      background: $color-primary;
    }

    &, &:hover {
      transition: $transition-bg;
    }

    .right-wrapper {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: $spacing-margin;

      i {
        margin-left: 0.5rem;
        font-size: 1.25rem;
      }
    }
  }

  img {
    width: 55px;
    border-radius: $border-radius-size;
    margin-right: $spacing-margin;
  }

  .store-details {
    line-height: 18px;
    display: flex;
    flex-direction: column;

    span {
      font-size: 16px;

      &.store-name {
        font-weight: 700;
      }
    }
  }
}

.multi-vendor-accordion {
  &, .card-header {
    border: 0;
    border-radius: 0;
  }

  .card-header {
    background: $color-grey-1;
    margin: 1px 1px 2px;

    &:hover {
      background: $color-secondary;
    }

    &, &:hover {
      transition: $transition-bg;
    }
  }

  .card-body {
    padding: 0;
  }
}

.multi-vendor-cart-content {
  flex-grow: 1;
  overflow-y: scroll;
  margin-bottom: 143px;
  border-top: 1rem solid $color-grey-1;
  box-shadow: inset 0 $box-shadow-inset-size $box-shadow-inset-size (-$box-shadow-inset-size) $color-grey-1;
}

.cart-section-checkout {
  background: #fff;
  padding: 15px;
  border-radius: 4px;
}
