.styled-control {
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 15px;
  display: flex;
  align-items: center;
  padding: 15px;
  padding-left: 45px;
  border-radius: 20px;
  border: 1px solid #ededed;
}
.styled-control input ~ .indicator {
  margin-left: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
.styled-control input[type="radio"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.styled-control .indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: white;
}
.styled-control.styled-control_radio .indicator {
  border-radius: 50%;
}
.styled-control:hover input ~ .indicator,
.styled-control input:focus ~ .indicator {
  outline: 1px solid #dfdfdf;
}
.styled-control input:checked ~ .indicator {
  background: #FF981F;
}
.styled-control:hover input:not([disabled]):checked ~ .indicator,
.styled-control input:checked:focus ~ .indicator {
  background: #f18200;
}
.styled-control input:disabled ~ .indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.indicator:after {
  content: '';
  position: absolute;
  display: none;
}
.styled-control input:checked ~ .indicator:after {
  display: block;
}
/*checkbox*/
.styled-control.styled-control_checkbox .indicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.styled-control.styled-control_checkbox input:disabled ~ .indicator:after {
  border-color: #7b7b7b;
}
/*radio*/
.styled-control.styled-control_radio .indicator:after {
  left: 7px;
  top: 7px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
}
.styled-control.styled-control_radio input:disabled ~ .indicator:after {
  background: #7b7b7b;
}
