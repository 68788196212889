.assistance {
  h3, h6 {
    color: map-get($colors, 'color_1')
  }

  h3.title {
    font-size: 18px;
  }

  .product-category {
    margin-bottom: -10px;
  }

  .text-bold {
    font-weight: 500;
  }

  .assistance-card,
  .assistance-card-product {
    width: 100%;
    margin: 15px 0;
    border-radius: 15px;
    color: map-get($colors, 'color_1');

    &.with-pointer {
      cursor: pointer;
    }

    &.no-pointer-events {
      pointer-events: none;
    }

    &.solid-bg {
      background: #f1f1f1;

      &:hover {
        background: #e5e5e5;
      }
    }

    &, &:hover {
      transition: background-color .25s ease;

      .chevron {
        &, &:hover {
          transition: font-size .15s ease;
        }
      }

      .assistance-card {
        &.solid-bg {
          &, &:hover {
            transition: background-color .25s ease;
          }
        }
      }
    }

    &:hover {
      background: #f1f1f1;

      .chevron {
        font-size: 20px;
      }
    }

    .card-image {
      margin-right: 15px;

      img {
        width: 100%;
      }
    }


    .chevron {
      display: flex;
      margin-left: auto;
      font-size: 18px;
      color: map-get($colors, 'color_1')
    }

    a {
      display: flex;
      align-items: center;
      color: inherit;
      text-decoration: none;
      flex-grow: 1;
    }
  }

  .assistance-card {
    display: flex;
    align-items: center;
    padding: 15px;
    box-shadow: 1px 1px 10px 0 #0000001c;

    .card-image {
      max-width: 65px;
    }

    .card-data {
      display: flex;
      flex-direction: column;

      .title {
        font-weight: 500;
        font-size: 15px;
      }

      .timestamp {
        color: #9b9b9b;
      }
    }
  }

  .assistance-card-product {
    padding: 5px;
    background-color: #00000008;

    &.has-replaced-product {
      & > .wrapper {
        align-items: flex-start;
      }

      p {
        margin-bottom: 0;
        font-weight: 500;
        text-decoration: underline;
        color: map-get($colors, 'color_5');
      }

      .assistance-card-product {
        position: relative;

        &, &:hover {
          background-color: map-get($colors, 'color_9');
        }

        &:before {
          position: absolute;
          transform: translateY(-50%) rotate(90deg);
          top: 50%;
          font-family: 'FontAwesome';
          content: "\f148";
          font-size: 22px;
          margin-left: -30px;
        }

        margin: 5px 0 0;

        .card-image {
          img {
            background-color: white;
          }
        }
      }
    }

    .wrapper {
      display: flex;
      align-items: center;
    }

    .card-image {
      max-width: 45px;

      img {
        border-radius: 15px;
        overflow: hidden;
        border: 1px solid #0000001c;
      }
    }

    .card-data {
      flex-grow: 1;

      .title {
        font-weight: 400;
      }
    }

    .bootstrap-select {
      border-radius: 15px;

      button {
        background-color: white;
        border-radius: 15px;
        padding-right: 10px;

        .filter-option {
          margin-bottom: -3px;
        }

        .dropdown-menu {
          padding: 0 5px!important;
        }
      }
    }
  }
}
